<template>
  <div class="writer-container">
    <link
      rel="stylesheet"
      href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
      integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
      crossorigin="anonymous"
    />
    <Header
      :title="headerTitle"
      :buttons="headerButtons"
      :language="language"
      @click:menu="onHeaderButtonClick"
      class="shadow"
    />
    <div class="container pt-5 pb-5">
      <div class="row py-3">
        <div class="col">
          <div
            v-cloak
            v-if="formData"
            class="row d-flex flex-row-reverse text-left"
          >
            <div v-cloak class="col-md-12 col-lg-8">
              <form class="form col-12 text-left" @submit.prevent="() => false">
                <div class="form-group">
                  <label>Comic Title</label>
                  <input
                    type="text"
                    v-model="formData.title"
                    class="form-control"
                    @click="onInputClick('title')"
                  />
                </div>

                <div class="form-group">
                  <label>Comic Title (English)</label>
                  <input
                    type="text"
                    v-model="formData.titleEn"
                    class="form-control"
                    @click="onInputClick('titleEn')"
                  />
                </div>

                <div class="form-group">
                  <label>Comic Summary</label>
                  <textarea
                    v-model="formData.summary"
                    class="form-control"
                    rows="3"
                    @click="onInputClick('summary')"
                  ></textarea>
                </div>

                <div class="form-group">
                  <label>Categories</label>
                  <div v-if="availableCategories">
                    <div>
                      <button
                        v-for="cat in availableCategories"
                        :key="cat.id"
                        class="btn btn-sm mr-2 mb-2 shadow-none"
                        :class="
                          formData.category.system.includes(cat.id)
                            ? 'btn-dark'
                            : 'btn-outline-secondary'
                        "
                        v-text="cat.attributes.name"
                        @click="comicActionAttachCategory(cat)"
                      ></button>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Language</label>
                  <select
                    v-model="selectedLanguage"
                    class="form-control"
                    @change="changeLanguage()"
                  >
                    <option
                      v-for="(item, index) in languages"
                      :key="index"
                      :value="item"
                      >{{ item }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <input
                    type="checkbox"
                    value="seriesStatus"
                    id="setState"
                    :checked="formData.state == 'COMPLETED'"
                    v-on:click="toggleSeriesState()"
                  />
                  <label for="setState">
                    Completed Series
                  </label>
                </div>
              </form>
            </div>

            <!-- cover-image -->
            <div v-cloak class="col-md-12 col-lg-4">
              <div class="col">
                <img
                  :src="series.coverImageUrl"
                  class="rounded cover-image"
                  height="300px"
                  width="300px"
                />
                <input
                  type="file"
                  hidden="hidden"
                  id="cover-image-upload"
                  ref="elCoverImageUpload"
                  v-on:change="comicActionUploadCoverImage($event)"
                />
                <button
                  class="update-img"
                  @click="$refs.elCoverImageUpload.click()"
                >
                  <i class="pratilipi-icons">&#xe81f;</i>
                </button>
              </div>
              <small
                >*Image should have max size of 1.5MB and max resolution of
                500px(W)*500px(H)</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col">
          <div
            class="btn-group mr-2 float-left"
            role="group"
            aria-label="First group"
          >
            <button
              type="button"
              class="btn btn-outline-secondary"
              :class="{ active: this.filteredByState == 'DRAFTED' }"
              @click="filterPartsByState('DRAFTED')"
            >
              Drafted
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              :class="{
                active: this.filteredByState == 'PUBLISHED',
              }"
              @click="filterPartsByState('PUBLISHED')"
            >
              Published
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              :class="{
                active: this.filteredByState == 'PUBLISHED,DRAFTED',
              }"
              @click="filterPartsByState('PUBLISHED,DRAFTED')"
            >
              All
            </button>
          </div>
          <div
            class="btn-group float-right"
            role="group"
            aria-label="Third group"
          >
            <button
              type="button"
              class="btn btn-outline-secondary float-right"
              @click="addEpisode()"
            >
              Add Episode
            </button>
          </div>
        </div>
      </div>
      <div class="row py-3">
        <table class="table">
          <thead class="text-left">
            <th></th>
            <th>Part No</th>
            <th>Display Title</th>
            <th>Last Updated</th>
            <th>State</th>
            <th></th>
          </thead>
          <tr
            class="text-left"
            v-for="{ id, attributes: part } in seriesParts"
            :key="id"
          >
            <td>
              <img
                @click="openEpisode(part)"
                style="cursor:pointer"
                :src="part.coverImageUrl"
                width="36px"
                height="36px"
              />
            </td>
            <td v-text="part.partNumber"></td>
            <td v-text="part.displayTitle"></td>
            <td v-text="timeAgo(part.updatedAt)"></td>
            <td>
              <span
                :class="{
                  'text-danger': part.state == 'PUBLISHED',
                  'text-secondary': part.state == 'DRAFTED',
                }"
                v-text="part.state"
              ></span>
              <div v-if="part.release && part.release.state == 'LOCKED'" :class="'text-secondary'">
                &amp; LOCKED
              </div>
            </td>
            <td>
              <div
                class="row"
                v-if="part.release == null || part.release.state == 'CANCELLED'"
                v-show="part.state !== 'PUBLISHED'"
              >
                <div class="col-8">
                  <datetime
                    type="datetime"
                    input-class="form-control mb-2 mr-sm-2"
                    :min-datetime="minDateTime"
                    :auto="true"
                    v-model="part.releaseData"
                  ></datetime>
                </div>
                <div class="col-4">
                  <button
                    @click="episodeActionSchedule(part, part.releaseData)"
                    type="submit"
                    class="btn btn-primary mb-2"
                  >
                    Schedule
                  </button>
                </div>
              </div>
              <p class="card-text" v-else-if="part.release.state == 'DONE'">
                <small class="text-muted"
                  >Scheduler published it on
                  {{ asCalendar(part.release.releaseAt) }}</small
                >
              </p>
              <p
                class="card-text"
                v-else-if="
                  part.release.state == 'PENDING' ||
                    part.release.state == 'LOCKED'
                "
              >
                <small class="text-muted"
                  >Release scheduled for
                  {{ asCalendar(part.release.releaseAt) }}</small
                >
                <button
                  class="btn btn-sm btn-link"
                  @click="episodeActionCancelRelease(part)"
                >
                  (cancel)
                </button>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <div class="row py-3" v-if="0">
        <div v-for="part in seriesParts" :key="part.id" class="col-6">
          <div class="card mb-3">
            <div class="row no-gutters">
              <div class="col-md-4" @click="openEpisode(part.attributes)">
                <img
                  :src="part.attributes.coverImageUrl"
                  width="200px"
                  height="200px"
                  class="card-img no-gutter"
                />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">
                    {{ part.attributes.displayTitle }}
                    (pt.{{ part.attributes.partNumber }})
                  </h5>
                  <p class="card-text">
                    <small class="text-muted"
                      >Last updated
                      {{ timeAgo(part.attributes.updatedAt) }}</small
                    >
                  </p>
                </div>
                <div class="card-body align-self-end">
                  <div
                    class="row"
                    v-if="
                      part.attributes.release == null ||
                        part.attributes.release.state == 'CANCELLED'
                    "
                  >
                    <div class="col-8">
                      <datetime
                        type="datetime"
                        input-class="form-control mb-2 mr-sm-2"
                        :auto="true"
                        v-model="part.releaseData"
                      ></datetime>
                    </div>
                    <div class="col-4">
                      <button
                        @click="episodeActionSchedule(part, part.releaseData)"
                        type="submit"
                        class="btn btn-primary mb-2"
                      >
                        Schedule
                      </button>
                    </div>
                  </div>
                  <p
                    class="card-text"
                    v-else-if="part.attributes.release.state == 'DONE'"
                  >
                    <small class="text-muted"
                      >Scheduler published it on
                      {{ asCalendar(part.attributes.release.releaseAt) }}</small
                    >
                  </p>
                  <p
                    class="card-text"
                    v-else-if="part.attributes.release.state == 'PENDING'"
                  >
                    <small class="text-muted"
                      >Release scheduled for
                      {{ asCalendar(part.attributes.release.releaseAt) }}</small
                    >
                    <button
                      class="btn btn-sm btn-link"
                      @click="episodeActionCancelRelease(part)"
                    >
                      (cancel)
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="model-delete-drafted" centered title="Drafted Episodes" @ok="onModalDeleteDraftedOkClick">
      <p>Select drafted episode(s) to be deleted: {{ this.checkedIds.length }}.</p>
      <div :style="{'margin-top': '1rem'}"></div>
      <div v-for="part in this.draftedParts" :key="part.id"
           :style="{'display': 'flex', 'align-items': 'center', 'margin-bottom': '1rem', 'gap': '1rem'}"
      >
        <b-checkbox :checked="modalCheckboxChecked(part)" @change="onModalDeleteDraftedCheckedBoxClick(part)" />
        <img
            :src="part.attributes.coverImageUrl"
            :style="{'border-radius': '0.3rem'}"
            width="70px"
            height="70px"
        />
        <div :style="{'flex-shrink': '0', 'display': 'flex', 'flex-direction': 'column', 'gap': '0.3rem'}">
          <span :style="{'font-size': 'x-small'}">Part: {{ part.attributes.partNumber }}</span>
          <span>{{ part.attributes.displayTitle }}</span>
          <span :style="{'font-size': 'small', 'opacity': '0.7'}">Updated: {{ timeAgo(part.attributes.updatedAt) }}</span>
        </div>
        <small class="text-muted"
        >
          {{
            part.attributes.release ?
            "Release scheduled for" + asCalendar(part.attributes.release.releaseAt) : "Not scheduled yet"
          }}
          </small>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import Header from "../components/Header.vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import mixins from "@/mixins";

import {
  apiSeriesV2,
  apiComic,
  apiPratilipiV2,
  apiPratilipiV1,
} from "../http/DataAccessor";

export default {
  components: {
    Header,
    Datetime,
  },

  created() {
    if (!this.getUserDetails.authorId) {
      this.$router.replace({ name: "LoginPage" });
      return;
    }

    this.user = this.getUserDetails;
    this.init();
  },

   mixins: [mixins],

  watch: {
    seriesParts() {
      this.draftedParts = this.seriesParts.filter((part) => {
        return part.attributes.state === "DRAFTED";
      });
    }
  },

  data() {
    return {
      series: null,
      formData: null,
      seriesParts: [],
      draftedParts: [],
      checkedIds: [],
      errors: {},
      language: "",
      episodesContentType: "STORY",
      showImageModal: false,
      selectedLanguage: "",
      languages: [
        "HINDI",
        "MARATHI",
        "TAMIL",
        "ENGLISH",
        "GUJARATI",
        "BENGALI",
      ],
      filteredByState: "PUBLISHED,DRAFTED",
      dateTimeFormat: Datetime.DATETIME_MED,
      availableCategories: [],
    };
  },
  computed: {
    ...mapGetters(["getUserDetails"]),
    headerTitle() {
      return _.get(this.series, "displayTitle", "Loading Comic...");
    },
    headerButtons() {
      return [
        {
          id: "SAVE-META",
          text: "Save",
          class: "d-none d-md-inline-block",
        },
        {
          id: "INPROGRESS",
          text: "Publish",
          class: "d-none d-md-inline-block btn-danger",
          show: _.get(this.series, "state") === "DRAFTED",
        },
        {
          id: "UNPUBLISH",
          text: "Unpublish",
          class: "d-none d-md-inline-block btn-dark",
          show: _.get(this.series, "state") === "INPROGRESS",
        },
        {
          id: "DELETE-COMIC",
          text: "delete comic",
          class: "d-none d-md-inline-block btn-danger",
        },
      ].filter((b) => ("show" in b ? b.show : true));
    },
    minDateTime() {
      return moment().toISOString();
    },
  },
  methods: {
    toggleSeriesState() {
      this.formData.state =
        this.formData.state === "COMPLETED" ? "INPROGRESS" : "COMPLETED";
      this.onInputClick('series_status', this.formData.state);
    },
    async init() {
      const params = this.$router.currentRoute.params;

      const { data: comic } = await apiSeriesV2.getSeriesById(params.comicId);
      if (
        comic.state === "DELETED" ||
        this.user.authorId != comic.author.authorId
      ) {
        this.openAuthorDashboard();
      } else {
        this.onComicLoad(comic);
      }
    },

    modalCheckboxChecked(part) {
      return this.checkedIds.includes(part.id);
    },

    async onModalDeleteDraftedOkClick() {
      // console.log(`Deleting: ${this.checkedIds}`);
      const changes = this.formData;

      await this.deleteDraftedEpisodes(this.checkedIds)
      await this.updateComic(changes);
    },

    async onModalDeleteDraftedCheckedBoxClick(part) {
      if (this.checkedIds.includes(part.id)) {
        this.checkedIds = this.checkedIds.filter(id => id !== part.id);
      } else {
        this.checkedIds.push(part.id);
      }
    },

    async onHeaderButtonClick(id) {
      switch(id) {
        case "DELETE-COMIC": {
          this.triggerAnanlyticsEvent("CLICK_DELETE", "CONTROL", {
            SERIES_ID: this.series.seriesId,
            SERIES_NAME: this.series.titleEn
          });
          await this.deleteComic();
          break;
        }
        case "SAVE-META": {
          this.triggerAnanlyticsEvent("CLICK_SAVE_META", "", {
            SERIES_ID: this.series.seriesId,
            SERIES_NAME: this.series.titleEn
          });
          await this.saveMeta();
          break;
        }
        case "INPROGRESS": {
          this.triggerAnanlyticsEvent("CLICK_PUBLISH", "", {
            SERIES_ID: this.series.seriesId,
            SERIES_NAME: this.series.titleEn
          })
          await this.saveMeta({ state: "INPROGRESS" });
          break;
        }
        case "UNPUBLISH": {
          this.triggerAnanlyticsEvent("CLICK_UNPUBLISH", "", {
            SERIES_ID: this.series.seriesId,
            SERIES_NAME: this.series.titleEn,
          });
          await this.saveMeta({ state: "DRAFTED" });
          break;
        }
        default: {
          console.error("Invalid header button option");
        }
      }
    },
    onInputClick(field, choice) {
      this.triggerAnanlyticsEvent("CLICK_FIELD_" + field, "", {
        SERIES_ID: this.series.seriesId,
        SERIES_NAME: this.series.titleEn,
        CHOICE: choice
      })
    },

    async onComicLoad(comic) {
      // if (!comic.auth.isAuthorized) {
      //   return this.$router.replace({ name: "ComicsDashboard" });
      // }

      this.series = comic;
      this.formData = _.pick(this.series, [
        "title",
        "titleEn",
        "summary",
        "state",
      ]);
      this.$set(this.formData, "type", "STORY");
      this.$set(this.formData, "category", {
        system: this.series.category.system.map((s) => s.id),
        suggested: this.series.category.suggested.map((s) => s.id),
      });

      this.selectedLanguage = comic.language;

      await this.filterPartsByState("PUBLISHED,DRAFTED");

      this.availableCategories = await apiPratilipiV2
        .listTagsByLanguage(comic.language)
        .then(({ STORY }) => STORY.category);
    },

    async filterPartsByState(state) {
      this.seriesParts = [];
      this.filteredByState = state;

      const { seriesId } = this.series;
      const authorId = this.getUserDetails.authorId;
      const parts = await apiComic.getMetaPratilipis(seriesId, state, 500)
        .then((data) => {
          return data.map(item => ({
            id: item.pratilipiId,
            contentType: item.contentType,
            type: "PRATILIPI",
            attributes: {
              ...item,
              displayTitle: item.title,
              coverImageUrl: item.coverImage
            }
          }))
        })
      // const parts = await apiPratilipiV2.findBySeriesId(seriesId, authorId, state, 500);

      const partsInSeries = _.flatMap(state.toLowerCase().split(","), (state) =>
        _.get(this.series, ["seriesPart", state], [])
      );
      const partsInSeriesById = _.keyBy(partsInSeries, "pratilipiId");

      const releaseById = await apiComic
        .getRelease(_.map(parts, "id"))
        .then(({ data: releases }) => _.keyBy(releases, "pratilipiId"))
        .catch(() => ({}));

      this.seriesParts = parts.map((part) => {
        const { id } = part;

        part.attributes.release = _.get(releaseById, id, null);
        part.attributes.partNumber = _.get(partsInSeriesById, [id, "part"]);

        return part;
      });
    },

    async addEpisode() {
      const { seriesId, author, language, titleEn } = this.series;

      const data = {
        title: "A new chapter",
        type: "STORY",
        state: "DRAFTED",
        language: language,
        contentType: "IMAGE",
        authorId: parseInt(author.authorId),
      };

      this.triggerAnanlyticsEvent("CLICK_ADD_EPISODES", "", {
        SERIES_ID: seriesId,
        SERIES_NAME: titleEn
      })

      const pratilipi = await apiComic.createPratilipi(data);
      await apiSeriesV2.addPratilipi(seriesId, pratilipi.pratilipiId);
      this.openEpisode(pratilipi);
    },

    async changeLanguage() {
      this.onInputClick('language', this.selectedLanguage);
      this.$set(this.formData, "language", this.selectedLanguage);
      this.availableCategories = await apiPratilipiV2
        .listTagsByLanguage(this.selectedLanguage)
        .then(({ STORY }) => STORY.category);
    },

    timeAgo(ms) {
      return moment(ms).fromNow(); //eg. 1 day ago, 2 hours ago etc
    },

    asCalendar(ms) {
      return moment(ms).calendar();
    },

    async episodeActionSchedule(part, releaseAt) {
      part.release = await apiComic
        .setRelease(
          part.pratilipiId,
          moment(releaseAt)
            .minutes(0)
            .toISOString()
        )
        .catch((error) => {
          if (
            Array.isArray(error.response.data) &&
            error.response.data.length > 0 &&
            error.response.data[0].message
          ) {
            alert(error.response.data[0].message);
          }

          return Promise.reject(error);
        });
        this.triggerAnanlyticsEvent("CLICK_EPISODE_SCHEDULE", "CONTROL", {
          SERIES_ID: this.series.seriesId,
          SERIES_NAME: this.series.titleEn,
          UI_POSITION: part.partNumber
        })
    },

    async episodeActionCancelRelease(part) {
      const release = part.release;

      if (release.state != "PENDING") {
        return alert("Can cancel pending releases only");
      }

      this.triggerAnanlyticsEvent("CLICK_EPISODE_CANCEL_SCHEDULE", "CONTROL", {

                SERIES_ID: this.series.seriesId,
                SERIES_NAME: this.series.titleEn,
                UI_POSITION: part.partNumber
              })
      part.release = await apiComic.cancelRelease(release);
    },

    openEpisode(episode) {
      this.$router.push({
        name: "EpisodeDetail",
        params: {
          comic: this.series,
          comicId: this.series.seriesId,
          episode: episode,
          episodeId: episode.pratilipiId,
        },
      });
    },

    async updateComic(changes) {
      const { seriesId } = this.series;
      await apiSeriesV2
        .updateSeriesById(seriesId, changes)
        .then((response) => {
          this.$alert("changes are saved ", "Saved", "success");
          this.triggerAnanlyticsEvent("SEEN_ALERT_CHANGES_SAVED_SUCCESS", "", {
              SERIES_ID: seriesId
          });
          return response && response.data
        })
        .then((data) => this.onComicLoad(data))
        .catch((err) => {
          console.error(err)
          this.triggerAnanlyticsEvent("SEEN_ALERT_CHANGES_SAVED_FAILURE", "", {
              EVENT_DESCRIPTION: "changes Failed to save",
              SERIES_ID: seriesId
          });
          this.$alert(
            "changes Failed to save. Try again.",
            "Not Saved",
            "error"
          );
        });
    },

    async deleteDraftedEpisodes(draftedParts){
     let promises = []
      draftedParts.forEach(episode => {
        promises.push(apiPratilipiV2.destroy(episode))
      });
      await Promise.all(promises)
    },

    async saveMeta(changes = null) {
      console.log(`changes :: ${changes}`)
      const draftedParts = this.seriesParts.filter((part) => {
        return part.attributes.state === "DRAFTED";
      }).map((episode)=> episode.id);

      if (changes === null) {
        console.log('inside save meta clause')
        changes = this.formData;
        this.$confirm(
          "Are you sure you want to make these changes?",
          "Confirm",
          "warning"
        ).then(() => {
          if (draftedParts.length > 0 && changes.state === "COMPLETED") {
            this.$bvModal.show("model-delete-drafted");
          }
          else this.updateComic(changes);
        });
      }

      else if (changes.state === "DRAFTED") {
        console.log('inside unpublish clause')
        this.$confirm(
          "Are you sure you want to unpublish this comic?",
          "Confirm",
          "warning"
        ).then(() => {
          this.triggerAnanlyticsEvent("CLICK_UNPUBLISH_POPUP_CONFIRM", "CONTROL", {
            SERIES_ID: seriesId,
            SERIES_NAME: titleEn
          });
          this.updateComic(changes);
        }, () => {
          this.triggerAnanlyticsEvent("CLICK_UNPUBLISH_POPUP_CANCEL", "CONTROL", {
            SERIES_ID: seriesId,
            SERIES_NAME: titleEn
          });
        });
      }

      else if (changes.state === "INPROGRESS") {
        if (this.formData.summary.length === 0) {
          this.$alert("Summary cannot be empty", "Can't publish", "error");
          this.triggerAnanlyticsEvent("SEEN_ALERT_CANT_PUBLISH", "", {
              SERIES_ID: seriesId,
              EVENT_DESCRIPTION: "Summary cannot be empty"
          });
          return;
        }

        const publishedParts = this.seriesParts.filter((part) => {
          return part.attributes.state === "PUBLISHED";
        });

        if (publishedParts.length === 0) {
          this.$alert("No published parts!", "Can't publish", "error");
          this.triggerAnanlyticsEvent("SEEN_ALERT_CANT_PUBLISH", "", {
              SERIES_ID: seriesId,
              EVENT_DESCRIPTION: "No published parts!"
          });
          return;
        }

        this.$confirm(
          "Are you sure you want to publish this comic?",
          "Confirm",
          "warning"
        ).then(() => {
          this.updateComic(changes);
            this.triggerAnanlyticsEvent("CLICK_PUBLISH_POPUP_CONFIRM", "CONTROL", {
            SERIES_ID: seriesId,
            SERIES_NAME: titleEn
        });
        }, () => {
            this.triggerAnanlyticsEvent("CLICK_PUBLISH_POPUP_CANCEL", "CONTROL", {
            SERIES_ID: seriesId,
            SERIES_NAME: titleEn
          });
        });
      }
    },

    async deleteComic() {
      this.$confirm(
        "Are you sure you want to delete this comic?",
        "Confirm",
        "warning"
      ).then(async () => {
        const { seriesId, titleEn } = this.series;
        const { data } = await apiSeriesV2.deleteSeries(seriesId);
        this.openAuthorDashboard();
        this.triggerAnanlyticsEvent("CLICK_DELETE_POPUP_CONFIRM", "CONTROL", {
          SERIES_ID: seriesId,
          SERIES_NAME: titleEn
        });
      }, () => {
         const { seriesId, titleEn } = this.series;
        this.triggerAnanlyticsEvent("CLICK_DELETE_POPUP_CANCEL", "CONTROL", {
          SERIES_ID: seriesId,
          SERIES_NAME: titleEn
        });
      });
    },

    async comicActionUploadCoverImage(ev) {
      const file = ev.target.files[0];
      const img = new Image();
      let checkImageDimensions = new Promise((resolve, reject) => {
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          if (img.width > 500 || img.height > 500 || file.size > 1572864) {
            this.$alert(
              "Image size should not exceed 1.5MB and maximum resolution should be 500 pixels(W) X 500 pixels(H)",
              "Upload Error!",
              "error"
            );
            this.triggerAnanlyticsEvent("SEEN_COVER_IMAGE_ALERT", "CONTROL", {
              IMG_HEIGHT: img.height,
              IMG_WIDTH: img.width,
              SERIES_ID: seriesId,
              SERIES_NAME: titleEn
            });
            reject();
          } else {
            resolve();
          }
        };
      });

      checkImageDimensions.then(async () => {
        const data = await apiPratilipiV1.saveSeriesCoverImage(
          this.series.seriesId,
          file
        );
        this.$set(this.series, "coverImageUrl", data.coverImageUrl);
      });
    },

    async comicActionAttachCategory({ id, attributes }) {
      const name = attributes && attributes.name
      const index = this.formData.category.system.indexOf(id);
      if (index == -1) {
        this.formData.category.system.push(id);
        this.onInputClick('category', name)
      } else {
        this.formData.category.system.splice(index, 1);
      }
    },

    openAuthorDashboard() {
      const params = { authorId: this.getUserDetails.authorId };
      this.$router.push({ name: "AuthorDashboard", params });
    },
  },
};
</script>
<style lang="scss">
.vdatetime-time-picker__list--hours {
  .vdatetime-time-picker__item:nth-child(-n + 0) {
    display: none;
  }
  .vdatetime-time-picker__item:nth-last-child(-n + 3) {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.writer-container {
  background-color: #f9f9f9;
}

.update-img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-70%);
  background: rgba(255, 255, 255, 0.4);
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 5px 10px;
  text-align: center;

  i {
    vertical-align: middle;
    font-size: 18px;
  }
}
</style>
